import { MOBILE_SCREEN_WIDTH } from './constants';

export const isThisScreenSizeMobileSize = (): boolean =>
    window.innerWidth <= MOBILE_SCREEN_WIDTH;

export const getNumber = (value: string): number =>
    +value.replace(',', '.').replace(' ', '');

export const isNonNegativeNumber = (value: string): boolean => {
    const newNumber = getNumber(value);
    return !isNaN(newNumber) && newNumber >= 0;
};
