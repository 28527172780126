const strings = {
    SINGE_CONTAINER_PACKING: 'Single Container Packing',
    CONTAINER: 'Container',
    ITEMS: 'Items',
    ID: 'Id',
    WDT: 'Wdt',
    HGT: 'Hgt',
    DPT: 'Dpt',
    WGT: 'Wgt',
    QTY: 'Qty',
    VR: 'VR',
    MAX_WGT: 'Max. wgt.',
    ADD_CONTAINER_BUTTON: 'Add container',
    ADD_ITEM_BUTTON: 'Add item',
    PACK: 'PACK',
    PACKING_RESULT: 'packing result',
    RESPONSE_TIME: 'Response time',
    CONTAINER1: 'Container #1',
    CONTAINER_ID: 'Container ID',
    DIMENSIONS: 'Dimensions',
    WIDTH: 'Width',
    HEIGHT: 'Height',
    DEPTH: 'Depth',
    PACKED_ITEMS: 'Packed items',
    SPACE_TAKEN: 'Space taken',
    WEIGHT_TAKEN: 'Weight taken',
    PACKIGN_TIME: 'Packing time',
    IMAGES_GENERATION_TIME: 'Images generation time',
    PACKING_VISUALIZATION: 'Packing visualization',
    IMAGES_SBS: 'Images showing single items in container',
    IMAGES_SEPARATED: 'Images showing packing step by step',
    NOT_PACKED_ITEMS: 'Items that have not been packed',
};

export default strings;
