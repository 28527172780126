import { actionTypes } from '../actions';

export const initialState = {
    app: {
        isMobile: false,
        bins: [],
        items: [],
    },
    packResponse: {
        data: null,
        loading: false,
        error: '',
    },
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.app.isMobileUpdate:
            return {
                ...state,
                app: {
                    ...state.app,
                    isMobile: action.data,
                },
            };
        case actionTypes.app.binsUpdate:
            return {
                ...state,
                app: {
                    ...state.app,
                    bins: action.data,
                },
            };
        case actionTypes.app.itemsUpdate:
            return {
                ...state,
                app: {
                    ...state.app,
                    items: action.data,
                },
            };
        case actionTypes.pack.loading:
            return {
                ...state,
                packResponse: {
                    ...state.packResponse,
                    loading: true,
                    error: '',
                },
            };

        case actionTypes.pack.error:
            return {
                ...state,
                packResponse: {
                    ...state.packResponse,
                    loading: false,
                    error: action.error,
                },
            };

        case actionTypes.pack.success:
            return {
                ...state,
                packResponse: {
                    ...state.packResponse,
                    loading: false,
                    data: action.data,
                },
            };

        default:
            return state;
    }
};
export default reducer;
