import './App.scss';

import React, { useEffect } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { actionTypes, getActionFunction } from './actions';
import { IDefaultState, IPackParams, IItem } from './types';
import { isThisScreenSizeMobileSize } from './utils';
import strings from './strings';
import ContainerBox from './components/ContainerBox';
import ItemsBox from './components/ItemsBox/index';
import Result from './components/Result';

interface IAppDispatchProps {
    updateIsMobile: (isMobile: boolean) => void;
    pack: (data: IPackParams) => void;
}

interface IAppProps extends IAppDispatchProps, IDefaultState {}

function App(props: IAppProps = {} as IAppProps) {
    const { updateIsMobile } = props;
    const { isMobile } = props.app; // TODO - mobile styling

    useEffect(() => {
        const handleResize = () => {
            let newIsMobile = isThisScreenSizeMobileSize();
            if (newIsMobile !== isMobile) {
                updateIsMobile(newIsMobile);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile, updateIsMobile]);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        props.pack({
            bins: props.app.bins,
            items: props.app.items.filter((item: IItem) => item !== undefined),
        });
        event.preventDefault();
        event.stopPropagation();
    };

    const isError: boolean = props?.packResponse?.error?.length > 0;
    const isError2: boolean = props?.packResponse?.data?.errors?.length > 0;
    const isData: boolean = !!props?.packResponse?.data;
    // TODO loading

    return (
        <div className="box-main box-product ">
            <Container>
                <Row>
                    <Col xs={12}>
                        <Form
                            name="packing"
                            id="form-product"
                            onSubmit={handleSubmit}
                        >
                            <div className="demo-box">
                                <h2 className="txt-regular f40 normalcase">
                                    {strings.SINGE_CONTAINER_PACKING}
                                </h2>
                                <Row>
                                    <Col xs={5}>
                                        <ContainerBox />
                                    </Col>
                                    <Col xs={7}>
                                        <ItemsBox />
                                    </Col>
                                </Row>
                            </div>
                            <Button variant="warning" size="lg" type="submit">
                                {strings.PACK}
                            </Button>
                        </Form>
                    </Col>
                </Row>
                {isError ? (
                    <Alert key={'error'} variant={'danger'}>
                        {props.packResponse.error}
                    </Alert>
                ) : isError2 ? (
                    props?.packResponse?.data?.errors.map((e) => (
                        <Alert
                            key={e.message}
                            variant={
                                e.level === 'critical' ? 'danger' : 'warning'
                            }
                        >
                            {e.message}
                        </Alert>
                    ))
                ) : isData ? (
                    <Result />
                ) : null}
            </Container>
        </div>
    );
}

const mapStateToProps = (state: IDefaultState): IDefaultState => ({
    ...state,
});

const mapDispatchToProps = (dispatch: any): IAppDispatchProps => ({
    updateIsMobile: (isMobile: boolean) =>
        dispatch(getActionFunction(actionTypes.app.isMobileUpdate, isMobile)),
    pack: (data: IPackParams) =>
        dispatch(getActionFunction(actionTypes.pack.loading, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
