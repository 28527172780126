import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import strings from '../strings';
import { IDefaultState, IItemNotPacked, IItemResponse } from '../types';

// TODO divide into several components

function Result(props: IDefaultState = {} as IDefaultState) {
    const notPacked = [
        ...props?.packResponse?.data?.not_packed_items,
        ...props?.packResponse?.data?.bins_packed[0]?.not_packed_items,
    ];
    return (
        <div style={{ marginTop: '20px' }}>
            {props?.packResponse.data && (
                <Row>
                    <Col xs={12}>
                        <div className="demo-box-result">
                            <div className="header">
                                <Row>
                                    <Col xs={6}>
                                        <h2>{strings.PACKING_RESULT}</h2>
                                    </Col>
                                    <Col xs={6}>
                                        {strings.RESPONSE_TIME}:{' '}
                                        {props.packResponse.data.response_time}
                                    </Col>
                                </Row>
                            </div>
                            <div className="content">
                                <Row>
                                    <Col xs={12}>
                                        <h3>{strings.CONTAINER1}</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2}>
                                        <h4>
                                            {strings.CONTAINER_ID}:{' '}
                                            {
                                                props.packResponse.data
                                                    .bins_packed[0].bin_data.id
                                            }
                                        </h4>
                                        <img
                                            src={
                                                props.packResponse.data
                                                    .bins_packed[0]
                                                    .image_complete
                                            }
                                            alt="image_complete"
                                            style={{
                                                maxWidth: '105px',
                                                maxHeight: '105px',
                                            }}
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        <h4>{strings.DIMENSIONS}:</h4>
                                        <Table className="p-sizes">
                                            <tbody>
                                                {[
                                                    {
                                                        name: strings.WIDTH,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .bin_data.w,
                                                    },
                                                    {
                                                        name: strings.HEIGHT,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .bin_data.h,
                                                    },
                                                    {
                                                        name: strings.DEPTH,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .bin_data.d,
                                                    },
                                                    {
                                                        name: strings.MAX_WGT,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .bin_data
                                                                .max_wg,
                                                    },
                                                ].map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col xs={4}>
                                        <Table className="p-info">
                                            <tbody>
                                                {[
                                                    {
                                                        name:
                                                            strings.PACKED_ITEMS,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .items.length,
                                                    },
                                                    {
                                                        name:
                                                            strings.SPACE_TAKEN,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .bin_data
                                                                .used_space +
                                                            ' %',
                                                    },
                                                    {
                                                        name:
                                                            strings.WEIGHT_TAKEN,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .bin_data
                                                                .used_weight +
                                                            ' %',
                                                    },
                                                    {
                                                        name:
                                                            strings.PACKIGN_TIME,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .packing_time +
                                                            ' (s)',
                                                    },
                                                    {
                                                        name:
                                                            strings.IMAGES_GENERATION_TIME,
                                                        value:
                                                            props.packResponse
                                                                .data
                                                                .bins_packed[0]
                                                                .images_generation_time +
                                                            ' (s)',
                                                    },
                                                ].map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <b>{item.name}</b>
                                                        </td>
                                                        <td>{item.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col xs={4}>
                                        <img
                                            src="./assets/box-result.png"
                                            alt=""
                                            className="img-box-result"
                                        />
                                    </Col>
                                </Row>
                                {notPacked.length > 0 && (
                                    <Row>
                                        <Col xs={12}>
                                            <div className="alert alert-warning">
                                                <p>
                                                    <b>
                                                        {
                                                            strings.NOT_PACKED_ITEMS
                                                        }
                                                    </b>
                                                </p>
                                                <ul>
                                                    {notPacked.map(
                                                        (
                                                            item: IItemNotPacked,
                                                            index: number
                                                        ) => (
                                                            <li key={index}>
                                                                <b>ID:</b>{' '}
                                                                {item.id},{' '}
                                                                <b>Quantity:</b>{' '}
                                                                {item.q}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs={12}>
                                        <h3>{strings.PACKING_VISUALIZATION}</h3>
                                        <ul className="packing-info">
                                            <li key={'image_beneration_time'}>
                                                <h4>
                                                    {
                                                        strings.IMAGES_GENERATION_TIME
                                                    }
                                                </h4>
                                                {props.packResponse.data.bins_packed[0].items.map(
                                                    (
                                                        item: IItemResponse,
                                                        index: number
                                                    ) => (
                                                        <img
                                                            key={index}
                                                            src={item.image_sbs}
                                                            alt=""
                                                            style={{
                                                                maxWidth:
                                                                    '90px',
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </li>
                                            <li key={'image_sbs'}>
                                                <h4>{strings.IMAGES_SBS}</h4>
                                                {props.packResponse.data.bins_packed[0].items.map(
                                                    (
                                                        item: IItemResponse,
                                                        index: number
                                                    ) => (
                                                        <img
                                                            key={index}
                                                            src={
                                                                item.image_separated
                                                            }
                                                            alt=""
                                                            style={{
                                                                maxWidth:
                                                                    '90px',
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
}

const mapStateToProps = (state: IDefaultState): IDefaultState => ({
    ...state,
});

export default connect(mapStateToProps, null)(Result);
