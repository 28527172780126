import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actionTypes, getActionFunction } from '../actions';
import strings from '../strings';
import { IBin } from '../types';
import { getNumber, isNonNegativeNumber } from '../utils';
import { IDefaultState } from './../types';

interface IContainerBoxDispatchProps {
    binsUpdate: (data: IBin[]) => void;
}

interface IContainerBoxProps
    extends IContainerBoxDispatchProps,
        IDefaultState {}

function ContainerBox(props: IContainerBoxProps = {} as IContainerBoxProps) {
    const [id, setId] = useState('Bin1');
    const [wdt, setWdt] = useState(10);
    const [hgt, setHgt] = useState(10);
    const [dpt, setDpt] = useState(10);
    const [maxWgt, seMaxWgt] = useState(0);

    const validateValue = (
        name: string,
        newValue: string,
        setValue: (value: any) => void
    ) => {
        let newBins = [...props.app.bins];
        if (name === 'id') {
            newBins[0][name] = newValue;
        } else {
            if (isNonNegativeNumber(newValue)) {
                // @ts-ignore
                newBins[0][name] = getNumber(newValue);
            } else {
                return;
            }
        }
        setValue(newValue);
        props.binsUpdate(newBins);
    };

    useEffect(() => {
        let newBins = [...props.app.bins];
        if (!newBins[0]) {
            newBins[0] = {
                id,
                w: +wdt,
                h: +hgt,
                d: +dpt,
                max_wg: +maxWgt,
            };
        }
        props.binsUpdate(newBins);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <h3>Container</h3>
            <div className="containers box-containers">
                <div className="table">
                    <div className="tr">
                        <div className="th" key={'id'}>
                            {strings.ID}
                        </div>
                        <div className="th" key={'wdt'}>
                            {strings.WDT}
                        </div>
                        <div className="th" key={'hgt'}>
                            {strings.HGT}
                        </div>
                        <div className="th" key={'dpt'}>
                            {strings.DPT}
                        </div>
                        <div className="th" key={'max_wgt'}>
                            {strings.MAX_WGT}
                        </div>
                        <div className="th" key={'bin'}></div>
                    </div>
                    <div className="tr">
                        <React.Fragment>
                            {[
                                { name: 'id', value: id, setValue: setId },
                                { name: 'w', value: wdt, setValue: setWdt },
                                { name: 'h', value: hgt, setValue: setHgt },
                                { name: 'd', value: dpt, setValue: setDpt },
                                {
                                    name: 'max_wg',
                                    value: maxWgt,
                                    setValue: seMaxWgt,
                                },
                            ].map((s) => (
                                <div className="td" key={s.name}>
                                    <Form.Control
                                        required
                                        type="text"
                                        key={s.name}
                                        value={s.value as string}
                                        onChange={(e) =>
                                            validateValue(
                                                s.name,
                                                e.target.value,
                                                s.setValue
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </React.Fragment>
                        <div className="td" key={'bin_icon'}>
                            <button
                                className="del-row del-prod"
                                type="button"
                                style={{
                                    background: `transparent url(./assets/icon-trash.png) no-repeat center center`,
                                }}
                                disabled
                            ></button>
                        </div>
                    </div>
                </div>
                <Button variant="secondary" disabled>
                    {strings.ADD_CONTAINER_BUTTON}
                </Button>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IDefaultState): IDefaultState => ({
    ...state,
});

const mapDispatchToProps = (dispatch: any): IContainerBoxDispatchProps => ({
    binsUpdate: (data: IBin[]) =>
        dispatch(getActionFunction(actionTypes.app.binsUpdate, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerBox);
