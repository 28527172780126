import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import { actionTypes, getActionFunction } from '../../actions';
import { IDefaultState, IItem } from '../../types';
import { isNonNegativeNumber, getNumber } from '../../utils';

interface IItemDispatchProps {
    itemsUpdate: (data: IItem[]) => void;
}

interface IOwnProps {
    id: number;
}
interface IStateProps extends IDefaultState, IOwnProps {}

interface IItemProps extends IItemDispatchProps, IStateProps {}

function Item(props: IItemProps = {} as IItemProps) {
    const [hidden, setHidden] = useState(false);
    const [id, setId] = useState(`Item${props.id}`);
    const [wdt, setWdt] = useState(4);
    const [hgt, setHgt] = useState(5);
    const [dpt, setDpt] = useState(6);
    const [wgt, setWgt] = useState(0);
    const [qty, setQty] = useState(5);

    const validateValue = (
        name: string,
        newValue: string,
        setValue: (value: any) => void
    ) => {
        let newItems = [...props.app.items];
        if (name === 'id') {
            newItems[props.id][name] = newValue;
        } else {
            if (isNonNegativeNumber(newValue)) {
                // @ts-ignore
                newItems[props.id][name] = getNumber(newValue);
            } else {
                return;
            }
        }
        setValue(newValue);
        props.itemsUpdate(newItems);
    };

    const removeItem = () => {
        const newItemsProps = [...props.app.items];
        delete newItemsProps[+props.id];
        props.itemsUpdate(newItemsProps);
        setHidden(true);
    };

    useEffect(() => {
        let newItems = [...props.app.items];
        if (!newItems[props.id]) {
            newItems[props.id] = {
                id,
                w: +wdt,
                h: +hgt,
                d: +dpt,
                wg: +wgt,
                q: +qty,
                vr: 0, // TODO CHECKBOX
            };
        }
        props.itemsUpdate(newItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (hidden) {
        return null;
    }
    return (
        <div className="tr" key={props.id}>
            <React.Fragment>
                {[
                    { name: 'id', value: id, setValue: setId },
                    { name: 'w', value: wdt, setValue: setWdt },
                    { name: 'h', value: hgt, setValue: setHgt },
                    { name: 'd', value: dpt, setValue: setDpt },
                    { name: 'wg', value: wgt, setValue: setWgt },
                    { name: 'q', value: qty, setValue: setQty },
                ].map((s) => (
                    <div className="td" key={s.name}>
                        <Form.Control
                            required
                            id={s.name}
                            key={'in_' + s.name}
                            type="text"
                            value={s.value as string}
                            onChange={(e) =>
                                validateValue(
                                    s.name,
                                    e.target.value,
                                    s.setValue
                                )
                            }
                        />
                    </div>
                ))}
                <div className="td" key={'checkbox'}>
                    {/* TODO: checkbox is optional
                    <div>
                        <label style={{ position: 'relative' }}>
                            <span
                                className="icon-checkbox checked"
                                style={{
                                    background:
                                        'url(./assets/checkbox.png) no-repeat 0 0',
                                }}
                            ></span>
                            <input
                                type="checkbox"
                                value="1"
                                style={{
                                    visibility: 'hidden',
                                    position: 'absolute',
                                }}
                            />
                        </label>
                    </div>
                    */}
                </div>
            </React.Fragment>
            <div className="td" key={'bin_icon'}>
                <button
                    className="del-row del-prod"
                    type="button"
                    style={{
                        background: `transparent url(./assets/icon-trash.png) no-repeat center center`,
                    }}
                    onClick={removeItem}
                ></button>
            </div>
        </div>
    );
}

const mapStateToProps = (
    state: IDefaultState,
    ownProps: IOwnProps
): IStateProps => ({
    ...state,
    ...ownProps,
});

const mapDispatchToProps = (dispatch: any): IItemDispatchProps => ({
    itemsUpdate: (data: IItem[]) =>
        dispatch(getActionFunction(actionTypes.app.itemsUpdate, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Item);
