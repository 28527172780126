import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import strings from '../../strings';
import { IDefaultState } from '../../types';
import Item from './Item';

function ItemsBox(props: IDefaultState = {} as IDefaultState) {
    const [itemId, setItemId] = useState(1);
    const [items, setItems] = useState([<Item id={0} key={0} />]);

    return (
        <React.Fragment>
            <h3>Items</h3>
            <div className="containers box-containers">
                <div className="table">
                    <div className="tr">
                        <div className="th" key={'id'}>
                            {strings.ID}
                        </div>
                        <div className="th" key={'wdt'}>
                            {strings.WDT}
                        </div>
                        <div className="th" key={'hgt'}>
                            {strings.HGT}
                        </div>
                        <div className="th" key={'dpt'}>
                            {strings.DPT}
                        </div>
                        <div className="th" key={'wgt'}>
                            {strings.WGT}
                        </div>
                        <div className="th" key={'qty'}>
                            {strings.QTY}
                        </div>
                        <div className="th" key={'vr'}>
                            {strings.VR}
                        </div>
                        <div className="th" key={'bin'}></div>
                    </div>
                    {items}
                </div>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setItems([...items, <Item id={itemId} key={itemId} />]);
                        setItemId(itemId + 1);
                    }}
                >
                    {strings.ADD_ITEM_BUTTON}
                </Button>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IDefaultState): IDefaultState => ({
    ...state,
});

export default connect(mapStateToProps, null)(ItemsBox);
