import { put } from 'redux-saga/effects';

// CORS error when using headers
// export const getHeaders = () => {
//     const headers: IHeader = {
//         'Content-Type': 'application/json',
//     };
//     return headers;
// };

export const getPOSTRequestInit = (params: { body: string }) => {
    const { body } = params;
    return {
        body,
        method: 'POST',
    };
};

// not used
// export const getGETRequestInit = () => {
//     return {
//         method: 'GET',
//     };
// };

export const fetchJSON = (url: string, requestInit = {}): Promise<Response> =>
    new Promise<Response>(async (resolve, reject) => {
        try {
            const response: Response = await fetch(url, requestInit);
            let response_1 =
                response.status >= 200 && response.status <= 207
                    ? response
                    : reject(response);
            let response_2 =
                (((response_1 &&
                    response_1.json().then((json) => ({
                        headers: response_1 && response_1.headers,
                        data: json,
                    }))) as unknown) as Response) || reject(response);
            return resolve(response_2);
        } catch (error) {
            return reject(error);
        }
    });

// TODO
export const getMessageFromErrorStatus = (error: { status: number }) => {
    let message;
    switch (error.status) {
        // case 500:
        //     message = 'Internal Server Error';
        //     break;
        // case 401:
        //     message = 'Invalid credentials';
        //     break;
        default:
            message = error.status;
    }
    return message;
};

export function* processFailedRequest(
    // TODO errorType should be action type
    params: { error: Response; errorType: any }
) {
    const { error, errorType } = params;
    yield put({
        type: errorType,
        error: getMessageFromErrorStatus(error),
    });
}
