import { all, call, put, takeLatest } from 'redux-saga/effects';

import { actionTypes } from '../actions';
import { IPackParams } from '../types';
import { API_KEY, BASE_URL, USERNAME } from './const';
import { fetchJSON, getPOSTRequestInit, processFailedRequest } from './utils';

function* pack(params: { data: IPackParams; type: string }) {
    const requestInit = getPOSTRequestInit({
        body: JSON.stringify({
            username: USERNAME,
            api_key: API_KEY,
            ...params.data,
            //#region default values for generating images
            item_colors_schema: 'default',
            params: {
                images_background_color: '255,255,255',
                images_bin_border_color: '59,59,59',
                images_bin_fill_color: '230,230,230',
                images_item_border_color: '214,79,79',
                images_item_fill_color: '177,14,14',
                images_item_back_border_color: '215,103,103',
                images_sbs_last_item_fill_color: '99,93,93',
                images_sbs_last_item_border_color: '145,133,133',
                images_width: 100,
                images_height: 100,
                images_source: 'file',
                images_sbs: 1,
                stats: 1,
                item_coordinates: 1,
                images_complete: 1,
                images_separated: 1,
            },
            //#endregion
        }),
    });

    try {
        const response = yield call(fetchJSON, `${BASE_URL}`, requestInit);

        yield put({
            type: actionTypes.pack.success,
            data: response.data.response,
        });
    } catch (error) {
        processFailedRequest({
            error,
            errorType: actionTypes.pack.error,
        });
    }
}

function* actionWatcher() {
    yield takeLatest(actionTypes.pack.loading, pack);
}

export default function* rootSaga() {
    yield all([actionWatcher()]);
}
