export const actionTypes = {
    app: {
        isMobileUpdate: 'IS_MOBILE_UPDATE',
        binsUpdate: 'BINS_UPDATE',
        itemsUpdate: 'ITEMS: UPDATE',
    },
    pack: {
        loading: 'PACK_LOADING',
        error: 'PACK_ERROR',
        success: 'PACK_SUCCESS',
    },
};

export const getActionFunction = (action: string, data?: any) => {
    return { type: action, data };
};
